"use client";

// Import Library
import Link from "next/link";
import { useSelector } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

// Import Custom Library
import useScrollTracking from "@/core/helpers/useScrollTracking";
import { getIsLogin } from "@/core/store/reducer/authSlice";
import { getIsErrorServer, getIsTimeout } from "@/core/store/reducer/apiSlice";

// Import Component
import ErrorServer from "@/app/500";
import ModalLogin from "@/components/login/Modal";
import ModalRegister from "@/components/register/Modal";
import Timeout from "@/app/timeout";

export default function LayoutAuth({ children }) {
    const idealLogo = "/images/logo/IDEAL.svg";

    // Scroll tracking
    useScrollTracking();

    const isLoggedIn = useSelector(getIsLogin);

    const isTimeout = useSelector(getIsTimeout);
    const isErrorServer = useSelector(getIsErrorServer);

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={
                process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY
            }
            scriptProps={{
                async: false,
                defer: false,
                appendTo: "head",
                nonce: undefined,
            }}
            useEnterprise={true}
        >
            <div className="p-4 bg-white shadow-lg text-center flex h-[72px] items-center justify-center fixed top-0 left-0 right-0 z-20">
                <Link href={isLoggedIn ? '/user' : '/'}>

                    <span className="sr-only">Ideal</span>
                    <img
                        className="h-14 w-auto"
                        src={idealLogo}
                        alt="Ideal"
                        title="ideal"
                        height={56}
                        width={128}
                    />

                </Link>
            </div>
            <main className="bg-neutral-25 min-h-screen">

                {isTimeout ?
                    <div className="sm:pt-[48px] sm:px-6"><Timeout /></div>
                    : isErrorServer ?
                        <div className="sm:pt-[48px] sm:px-6"><ErrorServer /></div>
                        :
                        <div className="sm:pt-[160px] sm:pb-6 sm:px-6">{children}</div>}

            </main>

            <ModalLogin />
            <ModalRegister />
        </GoogleReCaptchaProvider>
    );
}
